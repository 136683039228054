import { Box, Button, ButtonGroup, Flex, HStack, IconButton, Input, SkeletonCircle, Text } from "@chakra-ui/react";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow, Polygon, Data, useGoogleMap } from "@react-google-maps/api";
import { useRef, useState, useEffect } from "react";
import { MarkerClusterer } from '@react-google-maps/api';

const axios = require('axios').default;
axios.defaults.baseURL = 'http://nodejs.malditosanimales.com.ar';


const center = { lat:  -41.0335914405928, lng:   -62.82325422320192}

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

const optionsVendido = {
  fillColor: "lightblue",
  fillOpacity: 0.4,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const optionsEnVenta = {
  fillColor: "lightblue",
  fillOpacity: 0.1,
  strokeColor: "green",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};


const optionsCluster = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}


const onLoad = (infoWindow) => {
  //console.info("infoWindow: ", infoWindow);
};

const clicMapa = (mapa) => {
  console.info("Mapa: ", mapa.latLng.lat(), mapa.latLng.lng());
};


let key = 0;

function createKey(location) {
  key ++;
  return key
}

const esta = (vendido) => {
  if (vendido ==1) return true;
  return false;
}

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const onClick = (marker) => {
  console.info('I have been clicked!', marker)
  openInNewTab('https://www.pueblonuevo.com.ar/contacto.php');
};

const position1 = { lat: -41.03243494974771, lng: -62.825709224234345 };
const position2 = { lat: -41.03251461778598, lng: -62.82608759619257 };

function App() {

  useEffect(() => {
    axios.get("/lotes").then((response) => {
      setLotes(response.data);
    });
  }, []);
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBNSI7s8HJHcNJFAAg-vrKC2KnPWxPJVwk",
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [lotes, setLotes] = useState();

  if (!isLoaded) {
    return <SkeletonCircle size='1000' />;
  }

  if (!lotes) {
    return <SkeletonCircle size='1000' />;
  }

  //const str =  "{ hello: 'world', places: ['Africa', 'America', 'Asia', 'Australia'] }";
  // const json = JSON.stringify(eval("(" + str + ")"));

  let arregloLotes = lotes.map (
    (lote, index) => {  
          let manzana = [                 
                  {"sik":lote.sik, "lng":lote.Punto1Lng, "lat":lote.Punto1Lat}, 
                  {"sik":lote.sik, "lng":lote.Punto2Lng, "lat":lote.Punto2Lat},
                  {"sik":lote.sik, "lng":lote.Punto3Lng, "lat":lote.Punto3Lat}, 
                  {"sik":lote.sik, "lng":lote.Punto4Lng, "lat":lote.Punto4Lat}
                
              ]; 
          if (lote.Punto1Lng) 
          return manzana
        });

 // console.log("lotes ", lotes);
 // console.log("arregloLotes ", arregloLotes[2]);
 // console.log("Manzana52Lote03", Manzana52Lote03);
 // let esquinas = arregloLotes.filter(esquina => esquina.sik = 295);
  //console.log("esquinas ", esquinas);
  let esquinas = [];

  return (
    <Flex position="relative" flexDirection="column" alignItems="center" h="100vh" w="100vw">
      <Box position="absolute" left={0} top={0} h="100%" w="100%">
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={22}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            mapTypeId: "hybrid",
          }}
          onClick={clicMapa}
          onLoad={(map) => setMap(map)}
        >
          {/*<Polygon onLoad={onLoad} paths={Manzana81} options={optionsVendido}  />
          <Polygon onLoad={onLoad} paths={Manzana52Lote04} options={optionsVendido}  />*/}
          
          <MarkerClusterer options={optionsCluster}>
          {(clusterer) =>
            lotes.map((location) => (
              
              <>
              <Marker key={createKey(location)} position={location} clusterer={clusterer}  label={location.nroLote.toString()}
                  onClick={location.vendido?null:onClick} title={location.vendido?location.nombre:"Lote "+location.nroLote + " u$s"+location.Precio.toString()} icon={location.vendido?"vendido.png":"enVenta.png"}/>
            ))
              {/*location.vendido?console.log (location.sik, " vendido ", optionsVendido):console.log (location.sik, " enventa ", optionsEnVenta)}
              {/*{!location.vendido?
                  <Polygon onLoad={onLoad} paths={arregloLotes.filter(esquina => esquina.sik = location.sik)} options={{
                    fillColor: "lightblue",
                    fillOpacity: 0.1,
                    strokeColor: "red",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 1
                  }}  />
                  :
                  <Polygon onLoad={onLoad} paths={arregloLotes.filter(esquina => esquina.sik = location.sik)} options={{
                    fillColor: "lightblue",
                    fillOpacity: 0.1,
                    strokeColor: "white",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 1
                  }}  />}*/}
              {/*<Polygon onLoad={onLoad} paths={arregloLotes.filter(esquina => esquina.sik = location.sik)} options={esta(location.vendido)?optionsVendido:optionsEnVenta}/>*/}
              </>
            ))
          }
          </MarkerClusterer>
          { lotes.map((location) => (
              
              <Polygon onLoad={onLoad} paths={arregloLotes.filter(esquina => esquina.sik = location.sik)} options={esta(location.vendido)?optionsVendido:optionsEnVenta}/>
              
            ))
          }
          {/*<InfoWindow onLoad={onLoad} position={position}>
            <div style={divStyle}>
              <h1>Bienvenidos</h1>
            </div>
          </InfoWindow>*/}
        </GoogleMap>
      </Box>
      {/*{<Box p={4} borderRadius="lg" m={4} bgColor="white" shadow="base" minW="container.md" zIndex="1">
        <Text>Pueblo Nuevo - Balneario El Cóndor </Text>
      </Box>*/}
    </Flex>
  );
}

export default App;
